export const data = {
  en: {
    text: 'Decentralized Web3 Social Platform to Empower Communities, States and Sovereign Individuals.',
    list: [
      'Current Version: Alpha 0.5.0 (Private)'
    ]
  },
  ru: {
    text: 'Децентрализованные законы, правосудие и экономика  для цифровых племен и суверенных лиц',
    list: [
      'Текущая версия: Alpha 0.5.0 (Частная)'
    ]
  }
}
